import React from 'react'
import PropTypes from 'prop-types'
import Down from '../../assets/icons/chevron_down.svg'
import Up from '../../assets/icons/chevron_up.svg'
import '../../util/custom-element-closest-polyfill'
import '../../util/custom-element-scroll-polyfill'

const ScrollDown = ({ scrollTop }) => {
  const scroll = (e) => {
    const el = e.target.closest('.step-wrapper').querySelector('.roadmap-content')
    const scroll = scrollTop ? -el.clientHeight : el.clientHeight

    el.scrollBy({
      top: scroll,
      left: 0,
      behavior: 'smooth',
    })
  }
  return (
    <div className="scroll-down-wrapper">
      <button className="scroll-down-button" type="button" onClick={(e)=>scroll(e)}>{ scrollTop ? <Up /> : <Down />}</button>
    </div>
  )
}

ScrollDown.defaultProps = {
  scrollTop: false,
}

ScrollDown.propTypes = {
  scrollTop: PropTypes.bool,
}

export default ScrollDown;
