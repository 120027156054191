import { graphql, navigate } from 'gatsby';
import React from "react"
import Modal from 'react-modal';

import Layout from "../components/Layout/Layout"

import ContentContainer from '../components/ContentContainer/ContentContainer';
import FlexBox from '../components/FlexBox/FlexBox'
import RoadMap from '../components/RoadMap'

import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'
import _ from 'lodash'
import MenuContext from '../util/MenuContext';
import BaseHead from '../components/BaseHead/BaseHead';

const eachDeep = require('deepdash/eachDeep');

const roadmap = ({ data, pageContext: { language, languageUrls } }) => {
  const node = data.cms.node;

  const steps = [{
    id: "start",
    type: "start",
    title: node.title,
    introText: node.fieldIntroText
  }];

  eachDeep(
    node.fieldContent, ({ entity }, key, parent, context) => {
      if (entity.entityBundle === "roadmap_choices") {
        const step = {
          id: entity.entityId,
          type: "choices",
          title: node.title,
          items: entity.fieldItems,
          content: entity.fieldContent,
          depth: context.depth,
          parentId: _.get(context.parent, 'value.entity.entityId', "0"),
          _key: key,
          i: steps.length,
          anchor: entity.fieldAnchor
        }

        // First step in a choice path.
        if (parseInt(key) === 0 && context.depth > 1) {
          step.prev = context.parents[context.depth - 2].value.entity.entityId
        }

        steps.push(step)
      }
      if (entity.entityBundle === "roadmap_step") {
        const step = {
          id: entity.entityId,
          type: "step",
          title: node.title,
          content: entity.fieldContent,
          label: entity.fieldLabel,
          image: entity.fieldImage,
          frame: entity.fieldFrame,
          nextLink: entity.fieldMoreLink,
          depth: context.depth,
          parentId: _.get(context.parent, 'value.entity.entityId', "0"),
          _key: key,
          i: steps.length,
          anchor: entity.fieldAnchor
        }

        // First step in a choice path.
        if (parseInt(key) === 0 && context.depth > 1) {
          step.prev = context.parents[context.depth - 2].value.entity.entityId
        }

        steps.push(step)
      }
    },
    { childrenPath: 'entity.fieldItems' }
  );

  steps.push({
    id: "close",
    title: node.title,
    type: "close",
    depth: 0,
    i: steps.length
  })

  const menus = {
    top: _.get(data, 'topMenu.menuByName.links', []),
    footer: _.get(data, 'footerMenu.menuByName.links', []),
    popular: _.get(data, 'popularMenu.menuByName.links', [])
  }

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div>
          {modal ? (
            <RoadMap
              title={node.title}
              steps={steps}
              onClose={() => {
                navigate(closeTo)
              }}
            />
          ) : (
            <MenuContext.Provider value={menus}>
              <Layout language={language} urls={languageUrls}>
                <main id="content">
                  <ContentContainer width="small">
                    <FlexBox>
                      <Modal
                        isOpen
                        aria={{
                          labelledby: 'heading',
                          describedby: 'full_description',
                          modal: 'true',
                        }}
                        className="roadmap"
                        onRequestClose={null}
                        ariaHideApp={false}
                        style={{
                          overlay: {
                            backgroundColor: 'rgba(40, 40, 40, 0.75)',
                            zIndex: '101',
                          },
                          content: {
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            right: '0',
                            bottom: '0',
                            border: 'none',
                            borderRadius: '0',
                            padding: '0',
                          },
                        }}
                      >
                        <RoadMap
                          title={node.title}
                          steps={steps}
                          onClose={() => navigate('/')}
                        />
                      </Modal>
                    </FlexBox>
                  </ContentContainer>
                </main>
              </Layout>
            </MenuContext.Provider>
          )}
        </div>
      )}
    </ModalRoutingContext.Consumer>
  )
}

const RoadmapHead = ({ data }) => {
  const node = data.cms.node

  return (
    <BaseHead>
      <title>{node.title} | DigiD</title>
    </BaseHead>
  )
}

export const Head = RoadmapHead

// Recursive fragments are not permitted in GraphQL.
// The current query goes 12 levels deep...
export const query = graphql`
  query($vid: String!, $language: CMS_LanguageId!) {
    cms {
      node: nodeRevisionById(id: $vid, language: $language) {
        ... on CMS_NodeRoadmap {
          title
          fieldIntroText
          fieldContent {
            entity {
              entityId
              entityBundle
              ...RoadmapChoices
              ...RoadmapStep
              ... on CMS_ParagraphRoadmapChoices {
                fieldItems {
                  entity {
                    ...RoadmapChoice
                    ... on CMS_ParagraphRoadmapChoice {
                      fieldItems {
                        entity {
                          ...RoadmapChoices
                          ... on CMS_ParagraphRoadmapChoices {
                            fieldItems {
                              entity {
                                ...RoadmapChoice
                                ... on CMS_ParagraphRoadmapChoice {
                                  fieldItems {
                                    entity {
                                      ...RoadmapChoices
                                      ... on CMS_ParagraphRoadmapChoices {
                                        fieldItems {
                                          entity {
                                            ...RoadmapChoice
                                            ... on CMS_ParagraphRoadmapChoice {
                                              fieldItems {
                                                entity {
                                                  ...RoadmapChoices
                                                  ... on CMS_ParagraphRoadmapChoices {
                                                    fieldItems {
                                                      entity {
                                                        ...RoadmapChoice
                                                        ... on CMS_ParagraphRoadmapChoice {
                                                          fieldItems {
                                                            entity {
                                                              ...RoadmapChoices
                                                              ... on CMS_ParagraphRoadmapChoices {
                                                                fieldItems {
                                                                  entity {
                                                                    ...RoadmapChoice
                                                                    ... on CMS_ParagraphRoadmapChoice {
                                                                      fieldItems {
                                                                        entity {
                                                                          ...RoadmapChoices
                                                                          ... on CMS_ParagraphRoadmapChoices {
                                                                            fieldItems {
                                                                              entity {
                                                                                ...RoadmapChoice
                                                                                ... on CMS_ParagraphRoadmapChoice {
                                                                                  fieldItems {
                                                                                    entity {
                                                                                      ...RoadmapChoices
                                                                                      ... on CMS_ParagraphRoadmapChoices {
                                                                                        fieldItems {
                                                                                          entity {
                                                                                            ...RoadmapChoice
                                                                                            ... on CMS_ParagraphRoadmapChoice {
                                                                                              fieldItems {
                                                                                                entity {
                                                                                                  ...RoadmapChoices
                                                                                                  ... on CMS_ParagraphRoadmapChoices {
                                                                                                    fieldItems {
                                                                                                      entity {
                                                                                                        ...RoadmapChoice
                                                                                                        ... on CMS_ParagraphRoadmapChoice {
                                                                                                          fieldItems {
                                                                                                            entity {
                                                                                                              ...RoadmapChoices
                                                                                                              ... on CMS_ParagraphRoadmapChoices {
                                                                                                                fieldItems {
                                                                                                                  entity {
                                                                                                                    ...RoadmapChoice
                                                                                                                    ... on CMS_ParagraphRoadmapChoice {
                                                                                                                      fieldItems {
                                                                                                                        entity {
                                                                                                                          ...RoadmapChoices
                                                                                                                          ... on CMS_ParagraphRoadmapChoices {
                                                                                                                            fieldItems {
                                                                                                                              entity {
                                                                                                                                ...RoadmapChoice
                                                                                                                                ... on CMS_ParagraphRoadmapChoice {
                                                                                                                                  fieldItems {
                                                                                                                                    entity {
                                                                                                                                      ...RoadmapChoices
                                                                                                                                      ... on CMS_ParagraphRoadmapChoices {
                                                                                                                                        fieldItems {
                                                                                                                                          entity {
                                                                                                                                            ...RoadmapChoice
                                                                                                                                            ... on CMS_ParagraphRoadmapChoice {
                                                                                                                                              fieldItems {
                                                                                                                                                entity {
                                                                                                                                                  ...RoadmapChoices
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    topMenu: cms {
      menuByName(name: "top", language: $language) {
        links {
          label
          url {
            path
          }
        }
      }
    }

    footerMenu: cms {
      menuByName(name: "footer", language: $language) {
        links {
          ... Link
          links {
            ... Link
          }
        }
      }
    }

    popularMenu: cms {
      menuByName(name: "popular", language: $language) {
        links {
          label
          url {
            path
          }
        }
      }
    }
  }

  fragment RoadmapChoices on CMS_ParagraphRoadmapChoices {
    fieldTitle
    fieldAnchor
    fieldItems {
      entity {
        entityId
        entityBundle
      }
    }
    fieldContent {
      entity {
        entityId
        entityBundle
        ... Wysiwyg
        ... Accordion
        ... ReusableBlock
        ... ReusableContent
        ... InfoBlocks
        ... Rijksvideo
        ... Card
      }
    }
  }

  fragment RoadmapChoice on CMS_ParagraphRoadmapChoice {
    fieldTitle
    fieldMoreLink {
      url {
        path
      }
    }
    fieldItems {
      entity {
        entityId
        entityBundle
        ...RoadmapStep
      }
    }
  }

  fragment RoadmapStep on CMS_ParagraphRoadmapStep {
    fieldImage {
      entity {
        entityType
        ... on CMS_MediaScreenshotDesktop {
          __typename
          changed
          fieldXOffset
          fieldYOffset
          fieldOverlayWidth
          fieldOverlayHeight
          fieldOverlayType
          fieldMediaScreenshot: fieldMediaImage1 {
            screenshot:derivative(style: SCREENSHOTDESKTOP2X) {
              width
              height
              url
            }
          }
        }
        ... on CMS_MediaScreenshot {
          __typename
          changed
          fieldXOffset
          fieldYOffset
          fieldOverlayWidth
          fieldOverlayHeight
          fieldOverlayType
          fieldMediaScreenshot {
            screenshot: derivative(style: SCREENSHOT2X) {
              width
              height
              url
            }
          }
        }
      }
    }
    fieldLabel
    fieldFrame
    fieldAnchor
    fieldMoreLink {
      title
      url {
        path
      }
    }
    fieldContent {
      entity {
        entityId
        entityBundle
        ... Wysiwyg
        ... Accordion
        ... ReusableBlock
        ... ReusableContent
        ... InfoBlocks
        ... Rijksvideo
        ... Card
        ... LinkList
      }
    }
  }
`

export default roadmap
