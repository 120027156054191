import React from 'react'

const Marker = ({ overlay, zoomFactor }) => {
  const imageValue = {
    ...(overlay ? {
        width: overlay.fieldMediaScreenshot.screenshot.width,
        height: overlay.fieldMediaScreenshot.screenshot.height,
        imageXOffset: overlay.fieldXOffset * 2,
        imageYOffset: overlay.fieldYOffset * 2,
        imageOverlayWidth: overlay.fieldOverlayWidth * 2,
        imageOverlayHeight: overlay.fieldOverlayHeight * 2,
        imageOverlay: overlay.fieldOverlayType} : {})
  }
  const { width, height, imageXOffset, imageYOffset, imageOverlay, imageOverlayWidth, imageOverlayHeight} = imageValue

  // Turn position into a percentage
  const imagePerXOffset = ((100 * imageXOffset) / width)
  const imagePerYOffset = ((100 * imageYOffset) / height)
  const imagePerOlayH = ((100 * imageOverlayWidth) / width) * zoomFactor
  const imagePErOlayW = ((100 * imageOverlayHeight) / height) * zoomFactor

  //Create style for position frame.
  const style = {
    style: {
      left: `${imagePerXOffset}%`, top: `${imagePerYOffset}%`, width: `${imagePerOlayH}%`,  height: `${imagePErOlayW}%`}
  }

  const imageOverlayClass =  `marker marker-${imageOverlay}`
  return (<div className={imageOverlayClass} {...style}></div>)
}

export default Marker
