import React from 'react'
import PropTypes from 'prop-types'
import DragScroll  from '../DragScroll/DragScroll'
import FrameDesktop from '../../assets/images/desktop.svg'
import Marker from './Marker'

const OUTER_WIDTH = 424
const OUTER_HEIGHT = 302
const INNER_WIDTH = 384
const INNER_HEIGHT = 216

const ScreenshotDesktop = ({imageUrl, frame, zoomFactor, overlay, onZoom}) => {

  const originalWidth = overlay.fieldMediaScreenshot.screenshot.width
  const originalHeight = overlay.fieldMediaScreenshot.screenshot.height
  const XOffset = (INNER_WIDTH / originalWidth) * overlay.fieldXOffset * 2
  const YOffset = (INNER_HEIGHT / originalHeight) * overlay.fieldYOffset * 2

  const paddingX = 18
  const paddingTop = 18
  const paddingBottom = 64

  const backgroundPositionX = -((XOffset * zoomFactor) - XOffset)
  const backgroundPositionY = -((YOffset * zoomFactor) - YOffset)

  return (
    <DragScroll className="zoom-container desktop">
      <div onClick={()=>onZoom()}>
        <div className={`frame-wrapper ${frame ? `frame-${frame}` : ``}`}>
          <div
            className="screenshot-wrapper"
            style={{
              width: OUTER_WIDTH,
              height: OUTER_HEIGHT,
              padding: `${paddingTop}px ${paddingX}px ${paddingBottom}px`
            }}
          >
            <div
              className="screenshot-container"
              style={{
                height: INNER_HEIGHT,
                width: INNER_WIDTH,
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: `${INNER_WIDTH * zoomFactor}px ${INNER_HEIGHT * zoomFactor}px`,
                backgroundPositionX,
                backgroundPositionY,
                backgroundRepeat: "no-repeat",
                overflow: "hidden"
              }}
            >
              <Marker overlay={overlay} zoomFactor={zoomFactor} />
            </div>
            <FrameDesktop className="frame" />
          </div>
        </div>
      </div>
    </DragScroll>
  )
}

ScreenshotDesktop.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  frame: PropTypes.oneOf(["mobile", "desktop", "shadow"]),
  zoomFactor: PropTypes.number,
  onZoom: PropTypes.func.isRequired,
}

export default ScreenshotDesktop
