import React from 'react';
import PropTypes from 'prop-types';
import Close from '../../assets/icons/close.svg';
import CloseEN from '../../assets/icons/close-en.svg';
import { withTranslation } from 'react-i18next';

const TopBar = ({t, i18n, text, closeFunction}) => {
  const language = i18n.language

  return (
    <div className="modal-top-bar">
      {text && (
        <h1>{text}</h1>
      )}
      { closeFunction && <button
        title={t("roadmap.close")}
        className="modal-close-button"
        onClick={()=>closeFunction()}
      >
        {language.toLowerCase() === 'nl' ? <Close /> : <CloseEN />}
      </button> }
    </div>
  )
}

TopBar.propTypes = {
  text: PropTypes.string,
  closeFunction: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
}

TopBar.defaultProps = {
  text: 'DigiD app activeren',
  closeFunction: () => {},
}

export default withTranslation()(TopBar);
