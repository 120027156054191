import React from 'react';
import PropTypes from 'prop-types';
import Left from '../../assets/icons/chevron_left.svg';
import Right from '../../assets/icons/chevron_right.svg';
import { useTranslation } from 'react-i18next';

const ButtonBar = ({prev, next, nextEnabled, nextLabel}) => {
  const { t } = useTranslation();

  return (
    <div className="button-bar navigation-buttons">
      <button type="button" className="button tertiary" onClick={()=>prev()}>
        <Left />
        <span>{t('button_bar.previous')}</span>
      </button>
      <button type="button" className="button primary" disabled={nextEnabled ? false : true } onClick={()=>next()}>
        <span>{nextLabel || t('button_bar.next')}</span>
        <Right />
      </button>
    </div>
  )
}

ButtonBar.defaultProps = {
  prev: () => { },
  next: () => { },
  nextEnabled: false,
}

ButtonBar.propTypes = {
  prev: PropTypes.func,
  next: PropTypes.func,
  nextEnabled: PropTypes.bool,
}

export default ButtonBar;

