import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {ZoomButton, ZoomImage } from './';

const ZoomWrapper = ({imageID, image, wide, children}) => {
  const [zoomed, useZoom] = useState(false);
  const zoom = (id) => {
    const zoomedImage = document.getElementById(id);
    if(zoomedImage) {
      let currWidth = zoomedImage.clientWidth;
      if(zoomed){
        zoomedImage.style.width = (currWidth / 2) + "px";
      } else {
        zoomedImage.style.width = (currWidth * 2) + "px";
      }
      useZoom(!zoomed);
    }
  }
  return (
    <div className={`zoom-wrapper ${wide ? 'wide' : ''} ${zoomed ? 'zoomed' : ''}`}>
      <ZoomButton imageId={imageID} onZoom={()=>zoom(imageID)} zoomed={zoomed} />
      <ZoomImage imageId={imageID} image={image} onZoom={()=>zoom(imageID)}>
        {children}
      </ZoomImage>
    </div>
  )
}

ZoomWrapper.defaultProps = {
  wide: false,
}

ZoomWrapper.propTypes = {
  imageID: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  wide: PropTypes.bool,
}

export default ZoomWrapper;
