import { useState } from 'react';

export default () => {
  const [scrollTop, setScroll] = useState(false);
  const onScroll = (e) => {
    const el = e.target;
    const atTheEnd = el.scrollTop === (el.scrollHeight - el.offsetHeight);
    setScroll(atTheEnd);
  }
  return [scrollTop, onScroll];
}