import React, { useEffect } from 'react'
import useScroll from './useScroll'
import ButtonBar from '../ButtonBar'
import { TopBar } from './index'
import ScrollDown from '../ScrollDown'
import Paragraph from '../Paragraph/Paragraph'
import _ from 'lodash'
import Screenshot from '../Screenshot/Screenshot'
import { useTranslation } from 'react-i18next'

function findNext(steps, current) {
  const nextSteps = _.slice(steps, current.i);
  return  _.find(nextSteps, { parentId: current.parentId, _key: (parseInt(current._key) + 1).toString()})
    || _.find(nextSteps, (step) => (step.parentId === current.parentId && step.id !== current.id && step.depth >= current.depth))
    || _.find(nextSteps, (step) => (step.depth < current.depth))
    || _.last(nextSteps)
}

function getFrame(props) {
  const type = _.get(props, 'image.entity.__typename');

  switch (type) {
    case "CMS_MediaScreenshotDesktop":
      return "desktop"
    case "CMS_MediaScreenshot":
    default:
      const allowedFrames = ['mobile', 'shadow']
      const frame = _.get(props, 'frame')
      if (allowedFrames.indexOf(frame) !== -1) {
        return frame
      }
      return 'mobile'
  }
}

const Step = (props) => {
  const { t } = useTranslation();

  const [scrollTop, onScroll] = useScroll();

  const updateHistory = () => {
    let history = _.dropRight(props.stepHistory)
    props.setStepHistory(history)
  }

  const prevStep = () => {
    if (props.prev) {
      updateHistory()
      const prevKey = _.findIndex(props.steps, { id: props.prev }) + 1
      props.goToStep(prevKey)
    } else if (props.stepHistory.length) {
      let previousStep = _.last(props.stepHistory)
      updateHistory()
      props.goToStep(previousStep)
    } else {
      updateHistory()
      props.previousStep()
    }
  }

  let imageUrl = _.get(props, 'image.entity.fieldMediaScreenshot.screenshot.url', null);
  if (imageUrl) {
    // Add timestamp to fix caching issue.
    imageUrl += '&c=' + _.get(props, 'image.entity.changed')
  }
  const frame = getFrame(props)
  const next = findNext(props.steps, props);

  const nextStep = () => {
    props.setPrevScreen(props.currentStep)

    let history = props.stepHistory
    history.push(props.currentStep)
    props.setStepHistory(history)

    const nextLink =  _.get(props, 'nextLink.url.path');
    if (nextLink) {
      return document.location.href = nextLink
    }

    if (next && next.id === "close") {
      return props.onClose()
    } else if (next) {
      const nextKey = _.findIndex(props.steps, next) + 1
      props.goToStep(nextKey)
    } else {
      props.nextStep()
    }
  }

  useEffect(() => {
    if (props.isActive && props.shouldClose) {
      closeHandler()
    }
  })

  useEffect(() => {
    const contentRef = document.querySelector('.ReactModal__Content.roadmap')
    if (props.isActive && contentRef) {
      contentRef.focus()
    }
  }, [props.isActive])

  const closeHandler = () => {
    if (!props.isActive) {
      return
    }

    props.setPrevScreen(props.currentStep);
    props.lastStep()
    props.closeModal()
  }

  const overlay = _.get(props, 'image.entity', null)

  return (
    <div
      className="step-wrapper"
      onScroll={(e)=>onScroll(e)}
      style={props.isActive ? {display:'flex'} : {display:'none'}}
    >
      <ScrollDown scrollTop={scrollTop} />
      <TopBar
        text={props.title}
        closeFunction={closeHandler}
      />
      <div className={`roadmap-content ${(!frame || frame === 'mobile' || frame === 'shadow') ? 'row' : ''}`}>
        <div>
          {props.content.map(({ entity }) => (
            entity.entityId && (
              <Paragraph key={entity.entityId} entity={entity} overrideProps={{
                content_accordion: {
                  small: true
                }
              }} />
            )
          ))}
        </div>
        {imageUrl && <Screenshot imageUrl={imageUrl} frame={frame} overlay={overlay} />}
      </div>
      <ButtonBar prev={prevStep} next={nextStep} nextEnabled={true} nextLabel={_.get(props, 'nextLink.title', next.id === "close" ? t('step.close') : t('step.next'))} />
    </div>
  )
}

export default Step
