import React from 'react';
import PropTypes from 'prop-types';
import ZoomIn from '../../assets/icons/zoom.svg';
import ZoomOut from '../../assets/icons/minimize.svg';
import { Trans } from 'react-i18next';

const ZoomButton = ({zoomed, onZoom}) => {
  return (
    <button className="zoom-button" onClick={()=>onZoom()}>
      {zoomed ? (
        <React.Fragment><ZoomOut />
          <Trans i18nKey='zoom_button.zoom_out'>Klik om uit te zoomen</Trans>
        </React.Fragment>
      ) : (
        <React.Fragment><ZoomIn />
          <Trans i18nKey='zoom_button.zoom_in'>Klik om in te zoomen</Trans>
        </React.Fragment>
      )}
    </button>
  )
}

ZoomButton.propTypes = {
  zoomed: PropTypes.bool.isRequired,
  onZoom: PropTypes.func.isRequired,
}

export default ZoomButton;
