import React, { useEffect } from 'react';
import Button from '../Button/Button';
import { TopBar } from './index';
import { useTranslation } from 'react-i18next';

const Start = (props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (props.shouldClose) {
      closeHandler()
    }
  })

  useEffect(() => {
    const contentRef = document.querySelector('.ReactModal__Content.roadmap')
    if (props.isActive && contentRef) {
      contentRef.focus()
    }
  }, [props.isActive])

  const closeHandler = () => {
    if (!props.isActive) {
      return
    }

    props.setPrevScreen(props.currentStep)
    props.lastStep()
    props.closeModal()
  }

  return (
    <div className="step-wrapper" style={props.isActive ? {display:'flex'} : {display:'none'}}>
      <TopBar
        text=""
        closeFunction={closeHandler}
      />
      <div className="roadmap-content max-width">
        <h1 id="heading">{props.title}</h1>
        <p id="full_description" className="medium-text">{props.introText}</p>
        <Button className="primary full-width" onClick={props.nextStep}>{t('roadmap.start')}</Button>
      </div>
    </div>
  )
}

export default Start;
