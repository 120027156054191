import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { ZoomButton } from '../Zoom'
import ScreenshotMobile from './ScreenshotMobile'
import ScreenshotDesktop from './ScreenshotDesktop'
import ScreenshotShadow from './ScreenshotShadow'
import ScreenshotDefault from './ScreenshotDefault'

const Screenshot = ({imageUrl, frame, zoomFactor, overlay}) => {
  const [zoomed, useZoom] = useState(false);
  const zoom = () => useZoom(!zoomed)
  const frames = {
    'mobile': ScreenshotMobile,
    'desktop': ScreenshotDesktop,
    'shadow': ScreenshotShadow,
    'default': ScreenshotDefault
  }
  const ScreenshotType = frames[frame || 'default'];
  return (
    <div className={`zoom-wrapper`}>
      <ZoomButton onZoom={()=>zoom()} zoomed={zoomed} />
      <ScreenshotType
        imageUrl={imageUrl}
        frame={frame}
        zoomFactor={zoomed ? zoomFactor : 1}
        onZoom={() => zoom()} overlay={overlay}
      />
    </div>
  )
}

Screenshot.defaultProps = {
  zoomFactor: 1.75
}

Screenshot.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  frame: PropTypes.oneOf(["mobile", "desktop", "shadow"]),
  zoomFactor: PropTypes.number,
  overlay: PropTypes.object
}

export default Screenshot
