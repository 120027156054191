import React from 'react';
import PropTypes from 'prop-types';
import DragScroll  from '../DragScroll/DragScroll';

const ZoomImage = ({imageId, image, onZoom, children}) => {
  return (
    <DragScroll className="zoom-container">
      <div id={imageId} onClick={()=>onZoom()}>
        {children}
      </div>
    </DragScroll>
  )
}

ZoomImage.propTypes = {
  image: PropTypes.string.isRequired,
  imageId: PropTypes.string.isRequired,
  onZoom: PropTypes.func.isRequired,
}

export default ZoomImage;
