import React, { useState, useEffect } from 'react'
import StepWizard from 'react-step-wizard'
import { Start, Step, Choose } from './'
import * as Animate from './animation.module.scss'

class CustomStepWizard extends StepWizard {
  // Make sure to pass the state, so that the launching page is preserved in the background.
  updateHash = (activeStep) => {
    window.history.replaceState({ state: { modal: true } }, null, `#${this.state.hashKeys[activeStep]}`);
  }
}

const RoadMap = ({ title, steps, onClose }) => {
  const [prevScreen, setPrevScreen] = useState(1)
  const [stepHistory, setStepHistory] = useState([])

  let transitions = {
    enterRight: `${Animate.animated} ${Animate.fadeInRight}`,
    enterLeft: `${Animate.animated} ${Animate.fadeInLeft}`,
    exitRight: `${Animate.animated} ${Animate.fadeOutRight}`,
    exitLeft: `${Animate.animated} ${Animate.fadeOutLeft}`,
  }

  const getHeight = () => window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight;

  function useCurrentHeight() {
    let [height, setHeight] = useState(getHeight());

    useEffect(() => {
      let timeoutId = null;
      const resizeListener = () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => setHeight(getHeight()), 150);
      };
      window.addEventListener('resize', resizeListener);
      window.addEventListener('touchmove', resizeListener);
      return () => {
        window.removeEventListener('resize', resizeListener);
        window.removeEventListener('touchmove', resizeListener);
      }
    }, [])
    return height;
  }
  let height = useCurrentHeight();

  useEffect(() => {
    const modalRef = document.querySelector('.ReactModal__Overlay')
    modalRef.style.height = height + 'px';
    const contentRef = document.querySelector('.ReactModal__Content.roadmap')

    const handleClick = (e) => {
      if (e.button === 0) {
        if (!contentRef.contains(e.target)) {
          onClose(true)
        }
      }
    }

    const handleEscape = (e) => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        onClose(true)
      }
    }

    document.addEventListener('keydown', handleEscape, false)
    document.addEventListener('mousedown', handleClick, false)

    return function cleanup() {
      document.removeEventListener('mousedown', handleClick)
      document.removeEventListener('keydown', handleEscape)
    }
  })

  const closeModal = () => {
    onClose(true)
  }

  return (
    <CustomStepWizard transitions={transitions} isHashEnabled={true}>
      {steps.map(step => {
        const genericProps = {
          hashKey: step.anchor ? step.anchor.replace(/\s+/g, '-').toLowerCase() : step.id,
          key: step.id,
          setPrevScreen,
          setStepHistory,
          closeModal,
          stepHistory: stepHistory,
          steps: steps,
          title: title,
          onClose,
          ...step
        }
        switch(step.type) {
          case "start":
            return <Start hashKey={genericProps.hashKey} {...genericProps} />
          case "choices":
            return <Choose hashKey={genericProps.hashKey} {...genericProps} />
          case "step":
            return <Step hashKey={genericProps.hashKey} {...genericProps} />
          default:
            return <div key={step.id}>{step.entityBundle}</div>
        }
      })}
    </CustomStepWizard>
  )
}

export default RoadMap;
