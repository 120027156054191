import React from 'react'
import PropTypes from 'prop-types'
import DragScroll  from '../DragScroll/DragScroll'
import FrameMobile from '../../assets/images/mobile.svg'
import Marker from './Marker'

const INNER_WIDTH = 176
const INNER_HEIGHT = 352

const ScreenshotMobile = ({imageUrl, frame, zoomFactor, overlay, onZoom}) => {

  const originalWidth = overlay.fieldMediaScreenshot.screenshot.width
  const originalHeight = overlay.fieldMediaScreenshot.screenshot.height
  const XOffset = (INNER_WIDTH / originalWidth) * overlay.fieldXOffset * 2
  const YOffset = (INNER_HEIGHT / originalHeight) * overlay.fieldYOffset * 2

  const paddingX = 5
  const paddingY = 26
  const backgroundPositionX = -((XOffset * zoomFactor) - XOffset)
  const backgroundPositionY = -((YOffset * zoomFactor) - YOffset)

  return (
    <DragScroll className="zoom-container mobile">
      <div onClick={()=>onZoom()}>
        <div className={`frame-wrapper ${frame ? `frame-${frame}` : ``}`}>
          <div
            className="screenshot-wrapper"
            style={{
              width: 189,
              height: 378,
              padding: `${paddingY}px ${paddingX}px`
            }}
          >
            <div
              className="screenshot-container"
              style={{
                height: INNER_HEIGHT,
                width: INNER_WIDTH,
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: `${INNER_WIDTH * zoomFactor}px ${INNER_HEIGHT * zoomFactor}px`,
                backgroundPositionX,
                backgroundPositionY,
                backgroundRepeat: "no-repeat",
                overflow: "hidden"
              }}
            >
              <Marker overlay={overlay} zoomFactor={zoomFactor} />
            </div>
            <FrameMobile className="frame" />
          </div>
        </div>
      </div>
    </DragScroll>
  )
}

ScreenshotMobile.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  frame: PropTypes.oneOf(["mobile", "desktop", "shadow"]),
  zoomFactor: PropTypes.number,
  onZoom: PropTypes.func.isRequired,
}

export default ScreenshotMobile;
