import React, { useEffect } from 'react';
import Button from '../Button/Button';
import ButtonBar from '../ButtonBar';
import TopBar from './TopBar';
import Paragraph from '../Paragraph/Paragraph'
import _ from 'lodash';

function findNext(steps, current) {
  const nextSteps = _.slice(steps, current.i);
  return  _.find(nextSteps, { parentId: current.parentId, _key: (parseInt(current._key) + 1).toString()})
    || _.find(nextSteps, (step) => (step.depth < current.depth))
    || _.last(nextSteps);
}

const Choose = (props) => {
  useEffect(() => {
    const contentRef = document.querySelector('.ReactModal__Content.roadmap')
    if (props.isActive && contentRef) {
      contentRef.focus()
    }
  }, [props.isActive])

  useEffect(() => {
    if (props.isActive && props.shouldClose) {
      closeHandler()
    }
  })

  const updateHistory = () => {
    let history = _.dropRight(props.stepHistory)
    props.setStepHistory(history)
  }

  const closeHandler = () => {
    if (!props.isActive) {
      return
    }

    props.setPrevScreen(props.currentStep)
    props.lastStep()
    props.closeModal()
  }

  const prevStep = () => {
    if (props.prev) {
      updateHistory()
      const prevKey = _.findIndex(props.steps, { id: props.prev }) + 1;
      props.goToStep(prevKey)
    } else if (props.stepHistory.length) {
      let previousStep = _.last(props.stepHistory)
      updateHistory()
      props.goToStep(previousStep)
    } else {
      updateHistory()
      props.previousStep()
    }
  }

  const nextStep = (e, entity) => {
    props.setPrevScreen(props.currentStep);

    let history = props.stepHistory
    history.push(props.currentStep)
    props.setStepHistory(history)

    const link = _.get(entity, 'fieldMoreLink.url.path');

    if (link) {
      e.preventDefault();
      window.location.href = link;
    } else {
      const next = _.find(props.steps, { parentId: entity.entityId, _key: "0" }) || findNext(props.steps, props);
      const nextKey = _.findIndex(props.steps, next) + 1;
      props.goToStep(nextKey);
    }
  }

  return (
    <div className="step-wrapper" style={props.isActive ? {display:'flex'} : {display:'none'}}>
      <TopBar
        text={props.title}
        closeFunction={closeHandler}
      />
      <div className="roadmap-content max-width">
        {props.content.map(({ entity }) => (
          entity.entityId && (
            <Paragraph key={entity.entityId} entity={entity} overrideProps={{
              content_accordion: {
                small: true
              }}} />
          )
        ))}
        {props.items.map(({ entity }) => (
          <Button
            key={entity.entityId}
            className="primary full-width left"
            onClick={(e) => { nextStep(e, entity) }}>
            {entity.fieldTitle}
          </Button>
        ))}
      </div>
      <ButtonBar prev={prevStep} next={props.nextStep} nextEnabled={false}/>
    </div>
  )
}

export default Choose;
